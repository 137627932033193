//@ts-nocheck
import { useState, useEffect } from "react";
import Pagination from "../pagination/pagination";
import Options from "../Options/OptionsMenu";
import { Spin } from "antd";
import ReUseModal from "../Modal/ReuseableModal";
import { FaTimes } from "react-icons/fa";
import { useAppSelector, useAppDispatch } from "../../app/hook";
import LoadingComponent from "../LoadingComponent/skeleton-loading";
import ActionButton from "../utils/buttons/ActionButton";
import {
  addCampaignTweet,
  endCampaign,
  getAllCampaigns,
} from "../../utils/AuthSlice";
import Toggler from "../Toggler";
const CampaignTable = ({
  data,
  fetchCampaigns,
}: {
  data: {}[];
  fetchCampaigns: () => Promise<void>;
}) => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useAppSelector((state) => state.user);

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (!query) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    }
    if (
      userData?.userMainData?.accessLevel === "super admin" ||
      userData?.userMainData?.accessLevel === "admin"
    ) {
      setIsAdmin(true);
    }
  }, [query, data, userData]);

  useEffect(() => {
    setIsLoading(true);
    if (data?.length > 0) {
      setCurrentData(data);
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className="w-full font-inconsolata text-[20px] font-[600] clip-top-left-bottom-right flex flex-col bg-primary rounded-md p-3 lg:p-4  flow-hide">
      <div className="flex items-center gap-5 mb-4 ">
        <h1 className="text-white ">Search:</h1>
        <div className="w-full lg:w-1/2 max-w-[250px] border styled-border-radius border-white border-opacity-100  h-10 px-2 flex items-center">
          <input
            type="text"
            placeholder="Search Campaign..."
            className=" w-full border-none outline-none bg-transparent text-white "
            value={query}
            onChange={(e: any) => setQuery(e.target.value)}
          />
        </div>
      </div>
      {/* end of input space */}
      <div className="w-full flow-hide-x">
        <table className="table-fixed min-w-max lg:min-w-full lg:min-h-[140px]">
          <thead className="w-full bg-secondary   h-12 flex items-center rounded-md ">
            <tr className="w-full grid grid-cols-4   text-white place-items-center   px-4 gap-2 ">
              <th className=" w-full flex justify-start">Campaign Name</th>
              <th className=" w-full flex justify-start">Participants</th>
              <th className=" w-full flex justify-start">Tweets</th>
              <th className=" w-full flex justify-start">Status</th>
            </tr>
          </thead>
          <tbody className="gap-4 mt-4">
            {currentData.length > 0 ? (
              currentData.map((item: any, index: number) => (
                <SingleRow
                  item={item}
                  index={index}
                  key={item.id || index}
                  isAdmin={isAdmin}
                  fetchCampaigns={fetchCampaigns}
                />
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  {isLoading ? (
                    <>
                      <LoadingComponent />
                    </>
                  ) : (
                    "No leaderboard data available."
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filteredData?.length > 10 && (
        <div className="w-full px-4 mt-8">
          <Pagination
            data={filteredData}
            itemsPerPage={10}
            setCurrentData={setCurrentData}
            currentData={currentData}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignTable;

const SingleRow = ({
  item,
  index,
  isAdmin,
  fetchCampaigns,
}: {
  item: any;
  index: number;
  isAdmin: boolean;
  fetchCampaigns: () => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  const [endCampaignModalOpen, setEndCampaignModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [endingCampaign, setEndingCampaign] = useState(false);
  const [tweetLink, setTweetLink] = useState("");
  const [tweetID, setTweetID] = useState("");
  const [userName, setUsername] = useState("");
  const [isRetweet, setIsRetweet] = useState(false);
  const dispatch = useAppDispatch();
  const handleTweetLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value;
    setTweetLink(link);
    const match = link.match(/x\.com\/([\w\d_]+)\/status\/(\d+)/);
    const username = match?.[1] || "";
    const tweetID = match?.[2] || "";
    setTweetID(tweetID);
    setUsername(username);
  };

  const handleAddTweet = async () => {
    setIsLoading(true);
    const data = {
      campaignID: item?._id,
      link: tweetLink,
      tweetId: tweetID,
      username: userName,
      retweeted: isRetweet,
    };
    try {
      const response = await dispatch(addCampaignTweet(data)).unwrap();
      console.log(response);
      await fetchCampaigns();
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      console.error("Error adding tweet:", error);
      setIsLoading(false);
      setOpen(false);
    }
    console.log(item?._id);
  };

  const handleEndCampaign = async () => {
    setEndingCampaign(true);
    const data = {
      campaignID: item?._id,
    };
    try {
      const response = await dispatch(endCampaign(data)).unwrap();
      console.log(response);
      await fetchCampaigns();
      setEndingCampaign(false);
    } catch (error) {
      console.error("Error ending campaign:", error);
      setEndingCampaign(false);
    }
  };

  const handleSyncTweets = async () => {
    console.log("Synced");
  };

  const handleExportCampaign = () => {
    const leaderboardCopy = [...item?.users];
    const sortedData = leaderboardCopy.sort((a, b) => {
      // Compare points first
      if (b?.campaignPoints !== a?.campaignPoints) {
        return b?.campaignPoints - a?.campaignPoints;
      }
      // If points are equal, compare rank multiplier
      return b.userId?.rank?.multiplier - a.userId?.rank?.multiplier;
    });
    const data = sortedData;
    const result = data.map((item: any) => ({
      //@ts-ignore
      twitterUsername: item?.userId?.twitterUsername,
      //@ts-ignore
      campaignPoints: item?.campaignPoints,
      //@ts-ignore
      address: item?.userId?.address,
      //ts-ignore
      evmAddress: item?.userId?.addresses?.[0]?.evm,
    }));

    function convertToCSV(result) {
      const headers = Object.keys(result[0]).join(","); // Extract headers
      const rows = result.map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",")
      ); // Escape values
      return [headers, ...rows].join("\n");
    }

    // Export CSV
    function downloadCSV(csv, filename) {
      const blob = new Blob([csv], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    // Generate and download CSV
    const csvData = convertToCSV(result);
    downloadCSV(csvData, `${item?.name}.csv`);
  };
  return (
    <tr
      className="w-full grid grid-cols-4  gap-2 text-white place-items-center   px-4 h-10 mt-3 "
      key={index}
    >
      <td className=" w-full flex justify-start">
        {/* {item?.rank < 4 ? item?.rank : index + 1} */}
        <p className="text-white font-inconsolata capitalize">{item?.name}</p>
      </td>
      <td className=" w-full flex justify-start gap-3 items-center">
        <p className="text-white font-inconsolata">{item?.users?.length}</p>
      </td>
      <td className=" w-full flex justify-start gap-2 items-center">
        <p className="text-white font-inconsolata">{item?.tweets?.length}</p>
      </td>

      <td className=" w-full flex justify-between items-center gap-6">
        <div className="  flex  items-center gap-2">
          {item?.is_campaign_active === true ? (
            <p className="h-9 rounded-xl px-4 border-green-500 border text-green-500 flex items-center text-sm font-inconsolata">
              Active
            </p>
          ) : (
            <p className="h-9 rounded-xl px-4 border-red-500 border text-red-500 flex items-center text-sm font-inconsolata">
              Ended
            </p>
          )}
          <p className=" border-gr"></p>
        </div>
        {isAdmin && (
          <span>
            <Options>
              <div className="w-full flex flex-col py-2 px-4 gap-0 items-start">
                {item?.is_campaign_active === true ? (
                  <button
                    className="text-white w-full pl-2 text-start font-inconsolata font-semibold cursor-pointer text-sm hover:bg-secondary h-10 rounded-md"
                    onClick={() => setOpen(true)}
                  >
                    Add Tweet
                  </button>
                ) : (
                  <></>
                )}

                <button
                  className="text-white w-full pl-2 text-start font-inconsolata font-semibold cursor-pointer text-sm hover:bg-secondary h-10 rounded-md"
                  onClick={handleExportCampaign}
                >
                  Export Winners
                </button>

                {item?.is_campaign_active === true ? (
                  <button
                    className="text-white w-full pl-2 text-start font-inconsolata font-semibold cursor-pointer text-sm hover:bg-secondary h-10 rounded-md"
                    onClick={() => setEndCampaignModalOpen(true)}
                  >
                    End Campaign
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </Options>
          </span>
        )}
      </td>
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-between">
            <h1 className="text-white font-inconsolata text-2xl">Add Tweet</h1>
            <span
              className="text-secondary text-xl cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <FaTimes />
            </span>
          </div>
          <div className="flex flex-col mt-6">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="text-base text-white font-inconsolata mb-1"
              >
                Tweet Url
              </label>
              <input
                required
                type="text"
                className="w-full flex border-secondary  h-10  text-white outline-none  border-b bg-transparent placeholder:text-secondary font-inconsolata"
                placeholder="Enter Tweet Link"
                onChange={handleTweetLinkChange}
                value={tweetLink}
              />
            </div>
            <div className="flex flex-col mt-8">
              <label
                htmlFor=""
                className="text-base text-white font-inconsolata mb-1"
              >
                Tweet ID
              </label>
              <input
                type="text"
                className="w-full flex border-secondary  h-10  text-white outline-none  border-b bg-transparent placeholder:text-secondary font-inconsolata cursor-not-allowed"
                placeholder="Enter Tweet ID"
                value={tweetID}
                readOnly
              />
            </div>
            <div className="flex flex-col mt-8">
              <label
                htmlFor=""
                className="text-base text-white font-inconsolata mb-1"
              >
                Username
              </label>
              <input
                type="text"
                className="w-full flex border-secondary  h-10  text-white outline-none  border-b bg-transparent placeholder:text-secondary font-inconsolata cursor-not-allowed"
                placeholder="Username"
                value={userName}
                readOnly
              />
            </div>
            <div className="flex  mt-8 items-center gap-3 ">
              <label
                htmlFor=""
                className="text-base text-white font-inconsolata mb-1"
              >
                Retweeted?
              </label>
              <Toggler enabled={isRetweet} setEnabled={setIsRetweet} />
            </div>
            <div className="flex items-center justify-between gap-5">
              <ActionButton
                isLoading={isLoading}
                text={"Add Tweet"}
                isValid={isLoading}
                onPress={handleAddTweet}
                buttonType="sign-in-button"
              />

              {/* Tweet Syncing not available in V 1.0 */}
              {/* <ActionButton
                isLoading={isSyncing}
                text={"Sync All"}
                isValid={isSyncing}
                onPress={handleSyncTweets}
                buttonType="button-56"
              /> */}
            </div>
          </div>
        </div>
      </ReUseModal>
      <ReUseModal open={endCampaignModalOpen} setOpen={setEndCampaignModalOpen}>
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-between">
            <h1 className="text-white font-inconsolata text-2xl">
              End Campaign
            </h1>
            <span
              className="text-secondary text-xl cursor-pointer"
              onClick={() => setEndCampaignModalOpen(false)}
            >
              <FaTimes />
            </span>
          </div>
          <div className="flex flex-col mt-6">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="text-base text-white font-inconsolata mb-1"
              >
                Campaign ID
              </label>
              <input
                readOnly
                type="text"
                className="w-full flex border-secondary  h-10  text-white outline-none  border-b bg-transparent placeholder:text-secondary font-inconsolata cursor-not-allowed"
                placeholder="Campaign ID"
                value={item?._id}
              />
            </div>

            <div className="flex items-center justify-between gap-5">
              <ActionButton
                isLoading={endingCampaign}
                text={"End"}
                isValid={endingCampaign}
                onPress={handleEndCampaign}
                buttonType="sign-in-button"
              />
            </div>
          </div>
        </div>
      </ReUseModal>
    </tr>
  );
};
